import { createBrowserRouter } from "react-router-dom";
import Casino from "../pages/casino";
import LiveCasino from "../pages/live-casino/LiveCasino";
import { Promotion } from "../pages/promotions/Promotion";
import AppLayout from "../components/layout";
import { Dashboard } from "../pages/profile/Dashboard";
import ProtectedRoutes from "./ProtectedRoute";
import SportBetting from "../pages/sport-betting";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "",
        element: <Casino />,
      },
      {
        path: "/live-casino",
        element: <LiveCasino />,
      },
      {
        path: "/sport-betting",
        element: <SportBetting />,
      },
      {
        path: "/promotion",
        element: <Promotion />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: "/profile",
            element: <Dashboard />,
          },
        ],
      },
    ],
  },
]);

export default router;
