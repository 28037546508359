const TransactionHistory = () => {
  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Transaction History</h3>
        <div className="cainoform__wrap">
          <div className="row g-4">
            <div className="col-xl-6">
              <CasinoDate title="From" />
            </div>
            <div className="col-xl-6">
              <CasinoDate title="Until" />
            </div>
          </div>
        </div>
        <div className="casinoform__tabe">
          <table>
            <thead>
              <tr>
                <th>Game</th>
                <th>Payment Methods</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Ratio</th>
                <th>More</th>
              </tr>
            </thead>
            <tbody>
              <TransactionRow
                game="2PQ8B4KYMJ"
                paymentMethod="Bank / CC"
                amount="5,591 USD"
                status="Cancel"
                ratio="8.55"
              />
              <TransactionRow
                game="4TQRW5WXF4"
                paymentMethod="Credit Card"
                amount="5,591 USD"
                status="Pending"
                ratio="2.70"
              />
              <TransactionRow
                game="XR97K86R7Y"
                paymentMethod="Bank / CC"
                amount="5,591 USD"
                status="Cancel"
                ratio="8.50"
              />
              <TransactionRow
                game="VEJP8A5J87"
                paymentMethod="Bank"
                amount="5,591 USD"
                status="Complete"
                ratio="7.05"
              />
              <TransactionRow
                game="JKNFWEJ123"
                paymentMethod="Credit Card"
                amount="5,591 USD"
                status="Cancel"
                ratio="3.05"
              />
              <TransactionRow
                game="NC8S4QJ4K2"
                paymentMethod="Bank"
                amount="5,591 USD"
                status="Pending"
                ratio="3.20"
              />
              <TransactionRow
                game="DGPSN7SRM4"
                paymentMethod="Bank / CC"
                amount="5,591 USD"
                status="Complete"
                ratio="2.40"
              />
              <TransactionRow
                game="ZT3FA5D8N7"
                paymentMethod="Bank"
                amount="5,591 USD"
                status="Complete"
                ratio="1.95"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Component for casino date section
const CasinoDate = ({ title }: any) => {
  return (
    <div className="casino__date">
      <h4 className="f__title">{title}</h4>
      <div className="calender-bar">
        <input type="text" className="datepicker" placeholder="2023-2-2" />
        <i className="icon-calender" />
      </div>
    </div>
  );
};

// Component for a single transaction row
const TransactionRow = ({
  game,
  paymentMethod,
  amount,
  status,
  ratio,
}: any) => {
  return (
    <tr>
      <td>{game}</td>
      <td>{paymentMethod}</td>
      <td>{amount}</td>
      <td
        className={
          status === "Complete"
            ? "complate"
            : status === "Cancel"
            ? "cancel"
            : "pending"
        }
      >
        {status}
      </td>
      <td>{ratio}</td>
      <td className="bold">...</td>
    </tr>
  );
};

export default TransactionHistory;
