import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "../src/assets/glyphter-font/css/Glyphter.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { getUserData } from "./services/auth";
import { useEffect } from "react";
import useAuthStore from "./hooks/authStore";
import { setSessionItem } from "./helpers/sessionStorage";

function App() {
  const { setUser } = useAuthStore();

  const handleCheckToken = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
      document.cookie = `jwt=${token}`;
      try {
        const res = await getUserData(email);
        if (res.data) {
          setUser(res.data);
          setSessionItem("playerId", res.data?.uuid)
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    handleCheckToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
