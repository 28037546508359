import axios from "axios";
import { SignInDto, SignUpDto } from "../types/SignInModal";
import getConfig from "../config/getConfig";

const microPlayerClient = axios.create({
  baseURL: `${getConfig().API_URL_ADMINS_PLAYERS}/players`,
});

export const getAllLocales = async () => {
  return microPlayerClient.get("/locales");
};

export const getAllCurrencies = async () => {
  return microPlayerClient.get("/currencies");
};

export const signUp = async (payload: SignUpDto) => {
  return microPlayerClient.post("/new", payload);
};

export const getUserData = async (email: string) => {
  microPlayerClient.defaults.withCredentials = true;
  const token = localStorage.getItem("token");
  return microPlayerClient.get(`/email/${email}`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });
};

const microAuthClient = axios.create({
  baseURL: `${getConfig().API_URL_AUTH}/auth`,
});

export const signIn = async (payload: SignInDto) => {
  return microAuthClient.post("/signin", payload);
};
