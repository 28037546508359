import classNames from "classnames";
import ModalImage from "../img/modal/modal.png";
import { useEffect, useState } from "react";
import { ESignInTab, SignInDto, SignUpDto } from "../types/SignInModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  getAllCurrencies,
  getAllLocales,
  getUserData,
  signIn,
  signUp,
} from "../services/auth";
import useAuthStore from "../hooks/authStore";
import { setSessionItem } from "../helpers/sessionStorage";

type Props = {
  isShowing?: boolean;
  setIsShowing?: (isShowing: boolean) => void;
  initialTab?: ESignInTab;
};

const SignInModal = ({
  isShowing = false,
  setIsShowing,
  initialTab,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(ESignInTab.SignUp);
  const { setAuthObject, setUser } = useAuthStore();

  const [allLocales, setAllLocales] = useState<any>([]);
  const [allCurrencies, setAllCurrencies] = useState<any>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [locale, setLocale] = useState("");
  const [currency, setCurrency] = useState("");
  const [dob, setDob] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorSignUp, setErrorSignUp] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isSigningUp, setIsSigningUp] = useState(false);

  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errorSignIn, setErrorSignIn] = useState("");

  const fetchLocales = async () => {
    try {
      const res = await getAllLocales();
      const { data } = res;
      setAllLocales(data);
      setLocale(data[0].name);
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const res = await getAllCurrencies();
      const { data } = res;
      setAllCurrencies(data);
      setCurrency(data[0].code);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchLocales();
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (initialTab) {
      setSelectedTab(initialTab);
    }
  }, [initialTab]);

  const handleSignUp = async () => {
    if (!email || !password || !confirmPassword) {
      alert("Please fill in all fields");
      return;
    }
    if (password !== confirmPassword) {
      alert("Password and Confirm Password must be the same");
      return;
    }
    setErrorSignUp("");
    setIsSigningUp(true);
    const payload: SignUpDto = {
      email,
      currency,
      phoneNumber,
      country,
      locale,
      sex: gender,
      marketingTag: "string",
      password,
      consent: true,
      city,
      address,
      firstName,
      lastName,
      dob,
      username: email,
    };
    try {
      const res = await signUp(payload);
      if (res.status === 200) {
        setSuccessMessage("Sign up successfully!");
        if (!signInEmail) {
          setSignInEmail(email);
        }
      }
    } catch (e: any) {
      const errMsg = e?.response?.data?.error ?? "Something went wrong!";
      setErrorSignUp(errMsg);
    } finally {
      setIsSigningUp(false);
    }
  };

  const handleSignIn = async () => {
    if (!signInEmail || !signInPassword) {
      alert("Please fill in all fields");
      return;
    }
    setIsLoggingIn(true);
    setErrorSignIn("");
    try {
      const payload: SignInDto = {
        email: signInEmail,
        password: signInPassword,
        meta: {
          ip: "127.0.0.1", // TODO: replace me
          country: "string", // TODO: replace me
        },
      };
      const res = await signIn(payload);
      if (res.data) {
        document.cookie = `jwt=${res.data?.token}`
        setAuthObject(res.data);
        localStorage.setItem("token", res.data?.token);
        setSessionItem("playerId", res.data?.user?.uuid)
        localStorage.setItem("refreshToken", res.data?.refreshToken);
        localStorage.setItem("email", res.data?.user?.email);
        const responseForUser = await getUserData(res.data.user.email);
        setUser(responseForUser.data);
        setIsShowing?.(false);
      }
    } catch (e: any) {
      const errMsg = e?.response?.data?.error ?? "Something went wrong!";
      setErrorSignIn(errMsg);
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div
      className={classNames("modal register__modal", isShowing && "!flex")}
      onClick={() => {
        setIsShowing?.(false);
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close cursor-pointer"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setIsShowing?.(false);
              }}
            />
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row align-items-center g-4">
                <div className="col-lg-6">
                  <div className="modal__left">
                    <img src={ModalImage} alt="modal" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="modal__right">
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={classNames(
                            "nav-link",
                            selectedTab === ESignInTab.SignUp && "active"
                          )}
                          onClick={() => setSelectedTab(ESignInTab.SignUp)}
                        >
                          Sign Up
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={classNames(
                            "nav-link",
                            selectedTab === ESignInTab.SignIn && "active"
                          )}
                          onClick={() => setSelectedTab(ESignInTab.SignIn)}
                        >
                          Sign In
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent2">
                      <div
                        className={classNames(
                          "tab-pane fade",
                          selectedTab === ESignInTab.SignIn && "show active"
                        )}
                      >
                        <div className="form__tabs__wrap">
                          <div className="focus__icon">
                            <p>or login via social media accounts</p>
                            <div className="social__head">
                              <ul className="social">
                                <li>
                                  <a href="#0">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">
                                    <FontAwesomeIcon icon={faTwitter} />
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <form action="#0">
                            <div className="form__grp">
                              <label>Email</label>
                              <input
                                type="email"
                                placeholder="Your Email"
                                value={signInEmail}
                                onChange={(e) => setSignInEmail(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label>Password</label>
                              <input
                                type="password"
                                placeholder="Your Password"
                                value={signInPassword}
                                onChange={(e) =>
                                  setSignInPassword(e.target.value)
                                }
                              />
                              <span
                                id="#toggle-password3"
                                className="fa fa-fw fa-eye field-icon toggle-password3"
                              />
                            </div>
                            <div className="login__signup">
                              <div className="form-check">
                                <input
                                  className="form-check-input relative"
                                  type="checkbox"
                                  id="remem"
                                />
                                <label className="form-check-label">
                                  Remember me
                                </label>
                              </div>
                              <a href="#0">Forgot Password</a>
                            </div>
                            {errorSignIn && (
                              <div className="text-red-500 w-full text-center">
                                {errorSignIn}
                              </div>
                            )}
                            <div className="create__btn" onClick={handleSignIn}>
                              <div className="cmn--btn cursor-pointer flex items-center">
                                {isLoggingIn && (
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="animate-spinner"
                                  />
                                )}
                                <span className="ml-2 !inline">Sign In</span>
                              </div>
                            </div>
                            <p>
                              Do not have an account?{" "}
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  setSelectedTab(ESignInTab.SignUp)
                                }
                              >
                                Registration
                              </span>
                            </p>
                          </form>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          "tab-pane fade max-h-[500px] overflow-y-auto pr-8",
                          selectedTab === ESignInTab.SignUp && "show active"
                        )}
                      >
                        <div className="form__tabs__wrap">
                          <div className="focus__icon">
                            <p>or registration via social media accounts</p>
                            <div className="social__head">
                              <ul className="social">
                                <li>
                                  <a href="#0">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">
                                    <FontAwesomeIcon icon={faTwitter} />
                                  </a>
                                </li>
                                <li>
                                  <a href="#0">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <form action="#0">
                            <div className="form__grp">
                              <label>First Name</label>
                              <input
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label>Last Name</label>
                              <input
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label>Email</label>
                              <input
                                placeholder="Your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password9">Gender</label>
                              <select
                                className="form-select !bg-[#20283f] !border-none text-white !pt-[13px] !px-[18px] !pb-[15px]"
                                aria-label="Default select example"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                              >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                            <div className="form__grp">
                              <label>Country</label>
                              <input
                                placeholder="Country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label>City</label>
                              <input
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label>Address</label>
                              <input
                                placeholder="Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password9">Currency</label>
                              <select
                                className="form-select !bg-[#20283f] !border-none text-white !pt-[13px] !px-[18px] !pb-[15px]"
                                aria-label="Default select example"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              >
                                {allCurrencies.map((currency: any) => (
                                  <option
                                    value={currency.code}
                                    key={currency.code}
                                  >
                                    {currency.code}
                                  </option>
                                ))}
                              </select>
                              <span
                                id="#toggle-password9"
                                className="fa fa-fw fa-eye field-icon toggle-password9"
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password9">Locale</label>
                              <select
                                className="form-select !bg-[#20283f] !border-none text-white !pt-[13px] !px-[18px] !pb-[15px]"
                                aria-label="Default select example"
                                value={locale}
                                onChange={(e) => setLocale(e.target.value)}
                              >
                                {allLocales.map((locale: any) => (
                                  <option value={locale.name} key={locale.id}>
                                    {locale.name}
                                  </option>
                                ))}
                              </select>
                              <span
                                id="#toggle-password9"
                                className="fa fa-fw fa-eye field-icon toggle-password9"
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password10">
                                Phone Number
                              </label>
                              <input
                                type="number"
                                placeholder="Your Phone Number"
                                className="!pr-[18px]"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                              <span
                                id="#toggle-password10"
                                className="fa fa-fw fa-eye field-icon toggle-password10"
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password10">
                                Date of Birth
                              </label>
                              <input
                                type="date"
                                placeholder="Date of Birth"
                                className="!pr-[18px]"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                              />
                              <span
                                id="#toggle-password10"
                                className="fa fa-fw fa-eye field-icon toggle-password10"
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password10">
                                Password
                              </label>
                              <input
                                id="toggle-password10"
                                type="password"
                                placeholder="Your Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <span
                                id="#toggle-password10"
                                className="fa fa-fw fa-eye field-icon toggle-password10"
                              />
                            </div>
                            <div className="form__grp">
                              <label htmlFor="toggle-password9">Confirm</label>
                              <input
                                id="toggle-password9"
                                type="password"
                                placeholder="Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <span
                                id="#toggle-password9"
                                className="fa fa-fw fa-eye field-icon toggle-password9"
                              />
                            </div>
                            {errorSignUp && (
                              <div className="text-red-500 w-full text-center">
                                {errorSignUp}
                              </div>
                            )}
                            {successMessage && (
                              <div className="text-green-500 w-full text-center">
                                {successMessage}
                              </div>
                            )}
                            <div className="create__btn" onClick={handleSignUp}>
                              <div className="cmn--btn cursor-pointer flex items-center">
                                {isSigningUp && (
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="animate-spinner"
                                  />
                                )}
                                <span className="ml-2 !inline">Sign Up</span>
                              </div>
                            </div>
                            <p>
                              Do you have an account?{" "}
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  setSelectedTab(ESignInTab.SignIn)
                                }
                              >
                                Login
                              </span>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
