import { useState } from "react";

// Import images
import visa from "../../../img/profile/visa.png";
import webpay from "../../../img/profile/webpay.png";
import skrill from "../../../img/profile/skrill.png";
import much from "../../../img/profile/much.png";
import neteller from "../../../img/profile/neteller.png";
import visas from "../../../img/profile/visas.png";
import paypal from "../../../img/profile/paypal.png";
import bitcoin from "../../../img/profile/bitcoin.png";
import inovarav from "../../../img/profile/inovarav.png";
import phonerpe from "../../../img/profile/phonerpe.png";
import coins from "../../../img/profile/coins.png";
import yandex from "../../../img/profile/yandex.png";
import bvisa from "../../../img/profile/bvisa.png";
import visap from "../../../img/profile/visap.png";

const Withdraw = () => {
  const [amount, setAmount] = useState("$20.00");
  const [email, setEmail] = useState("");

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Logic to handle withdrawal
  };

  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Withdraw</h3>
        <div className="payment__cart__check">
          <h3 className="balance">Balance: € 0.00</h3>
          <div className="row g-4">
            <PaymentOption image={visa} />
            <PaymentOption image={webpay} />
            <PaymentOption image={skrill} />
            <PaymentOption image={much} />
            <PaymentOption image={neteller} />
            <PaymentOption image={paypal} />
            <PaymentOption image={bitcoin} />
            <PaymentOption image={inovarav} />
            <PaymentOption image={phonerpe} />
            <PaymentOption image={coins} />
            <PaymentOption image={yandex} />
            <PaymentOption image={visas} />
          </div>
        </div>
        <div className="deposit__complate">
          <h3>Complete Your Withdraw</h3>
          <div className="deposit__wallet">
            <div className="deopsit__wallet__items">
              <p>Deposit to Wallet</p>
              <div className="usd__chacnge">
                <span>USD</span>
                <div className="icons" id="profile-picture">
                  <i className="fas fa-pen" />
                </div>
              </div>
            </div>
            <div className="deopsit__wallet__items">
              <p>Payment Provider</p>
              <div className="usd__chacnge">
                <span>
                  <img src={bvisa} alt="visa" />
                </span>
                <div className="icons" id="profile-picture2">
                  <i className="fas fa-pen" />
                </div>
              </div>
            </div>
          </div>
          <div className="promo__code">
            <span className="promo">Promo Code</span>
            <a href="#0">
              <span>Enter Code</span>
              <span>
                <i className="fas fa-plus" />
              </span>
            </a>
          </div>
          <a href="#0" className="visa__card">
            <img src={visap} alt="visa" />
          </a>
          <ul className="quick-value">
            <li>
              <h5 className="active">20</h5>
            </li>
            <li>
              <h5>30</h5>
            </li>
            <li>
              <h5>100</h5>
            </li>
            <li>
              <a href="#0">Custom</a>
            </li>
          </ul>
          <form onSubmit={handleSubmit}>
            <div className="single-input mb__20">
              <input
                type="text"
                id="dAmount"
                placeholder="Enter Amount"
                value={amount}
                onChange={handleAmountChange}
                autoComplete="off"
              />
            </div>
            <div className="single-input">
              <input
                type="text"
                id="eemail"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                autoComplete="off"
              />
            </div>
            <div className="total__amount">
              <div className="items">
                <span>Amount Fee</span>
                <span>$20</span>
              </div>
              <div className="items">
                <span>Total</span>
                <span>{amount}</span>
              </div>
            </div>
            <div className="btn-area">
              <button type="submit" className="cmn--btn">
                <span>Withdraw</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Component for Payment Option
const PaymentOption = ({ image }: any) => {
  return (
    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div className="payment__cart__items">
        <input className="form-check-input" type="checkbox" id={image} />
        <label className="form-check-label">
          <img src={image} alt="visa" />
        </label>
      </div>
    </div>
  );
};

export default Withdraw;
