import bonus1 from "../../../img/profile/bonus1.jpg";
import bonus2 from "../../../img/profile/bonus2.jpg";
import bonus3 from "../../../img/profile/bonus3.jpg";
import bonus4 from "../../../img/profile/bonus4.jpg";

const Bonuses = () => {
  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Bonuses</h3>
      </div>
      <div className="bonuses__wrap">
        <h4 className="bonus">Sports bets</h4>
        <div className="row mb-4 g-4">
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <div className="promomy__items bonus__last__items">
              <div className="thumb">
                <img src={bonus1} alt="img" />
              </div>
              <div className="content">
                <h4>2022 Winter bonus 3</h4>
                <span className="smalltext">Use code at deposit</span>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <div className="promomy__items bonus__last__items">
              <div className="thumb">
                <img src={bonus2} alt="img" />
              </div>
              <div className="content">
                <h4>Betting Welcome Bonus</h4>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <h4 className="bonus">Sports bets</h4>
        <div className="row g-4">
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <div className="promomy__items bonus__last__items">
              <div className="thumb">
                <img src={bonus3} alt="img" />
              </div>
              <div className="content">
                <h4>Christmas Bonus</h4>
                <span className="smalltext">Use code at deposit</span>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <div className="promomy__items bonus__last__items">
              <div className="thumb">
                <img src={bonus4} alt="img" />
              </div>
              <div className="content">
                <h4>Welcome Casino Bonus</h4>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonuses;
