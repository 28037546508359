import { useEffect, useState } from "react";
import MyBetModal from "./MyBetModal";
import EventModal from "./EventModal";
import FooterMenu from "../../components/FooterMenu";
import { TopMenuTab } from "../../types/TopMenuTab";
import RowGame from "../../components/RowGame";
import TopMenu from "../../components/TopMenu";
import { getAllGameTypes, getAllGames } from "../../services/games";

const Casino = () => {
  const [selectedTab, setSelectedTab] = useState<TopMenuTab>(TopMenuTab.All);
  const [allGames, setAllGames] = useState<any>([]);
  const [availableGameTypes, setAvailableGameTypes] = useState<TopMenuTab[]>(
    []
  );

  const initGameListData = async () => {
    try {
      const res = await getAllGames();
      if (res.data.status === "ok") {
        const {
          data: { data },
        } = res;
        const mappedData = Object.keys(data).map((key) => data[key]);
        setAllGames(mappedData || []);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initGameTypeData = async () => {
    try {
      const res = await getAllGameTypes();
      if (res.data) {
        const mappedAvailableGameTypes = Object.keys(res.data).map(
          (key) => res.data[key]
        );
        setAvailableGameTypes(mappedAvailableGameTypes || []);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initGameListData();
    initGameTypeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TopMenu
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        availableGameTypes={availableGameTypes}
      />
      <section className="main__body__area">
        <div className="container">
          <div className="row g-0">
            <div className="casino__tab__area">
              <div className="tab-content" id="nav-tabCcasino">
                <div
                  className={
                    selectedTab === TopMenuTab.Slots ||
                    selectedTab === TopMenuTab.All
                      ? "tab-pane mt__30 fade text-white show active"
                      : "tab-pane mt__30 fade text-white"
                  }
                  id="casinot4"
                  role="tabpanel"
                  aria-labelledby="casinot4"
                >
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-slots" />
                    </span>
                    <span>Slots</span>
                  </div>
                  <RowGame
                    hasFirstBigItem={false}
                    items={allGames.filter(
                      (game: any) => game.game_type === TopMenuTab.Slots
                    )}
                  />
                </div>
                <div
                  className={
                    selectedTab === TopMenuTab.TableGames ||
                    selectedTab === TopMenuTab.All
                      ? "tab-pane mt__30 fade text-white show active"
                      : "tab-pane mt__30 fade text-white"
                  }
                  id="casinot5"
                  role="tabpanel"
                  aria-labelledby="casinot5"
                >
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-pmart" />
                    </span>
                    <span>Table Games</span>
                  </div>
                  <RowGame
                    hasFirstBigItem={false}
                    items={allGames.filter(
                      (game: any) => game.game_type === TopMenuTab.TableGames
                    )}
                  />
                </div>
                <div
                  className={
                    selectedTab === TopMenuTab.Instant ||
                    selectedTab === TopMenuTab.All
                      ? "tab-pane mt__30 fade text-white show active"
                      : "tab-pane mt__30 fade text-white"
                  }
                  id="casinot5"
                  role="tabpanel"
                  aria-labelledby="casinot5"
                >
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-pmart" />
                    </span>
                    <span>Instant Games</span>
                  </div>
                  <RowGame
                    hasFirstBigItem={false}
                    items={allGames.filter(
                      (game: any) => game.game_type === TopMenuTab.Instant
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </section>

      <MyBetModal />
      <EventModal />
    </div>
  );
};

export default Casino;
