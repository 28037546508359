export enum TopMenuTab {
  All = "All Games",
  Top = "Top",
  New = "New",
  Popular = "Popular",
  Slots = "Slots",
  TableGames = "Table",
  Jackpots = "Jackpots",
  LiveGames = "Live Games",
  Search = "Search",
  Instant = "Instant",
}

export type TopMenuItem = {
  name: TopMenuTab;
  icon: string;
  id: number;
};
