import { useEffect, useState } from "react";
import { TopMenuTab } from "../../types/TopMenuTab";
import RowGame from "../../components/RowGame";
import FooterMenu from "../../components/FooterMenu";
import TopMenu from "../../components/TopMenu";
import { getAllGameTypes, getAllGames } from "../../services/games";

const LiveCasino = () => {
  const [selectedTab, setSelectedTab] = useState<TopMenuTab>(TopMenuTab.All);
  const [, setAllGames] = useState<any>([]);
  const [availableGameTypes, setAvailableGameTypes] = useState<TopMenuTab[]>(
    []
  );

  const initGameListData = async () => {
    try {
      const res = await getAllGames();
      if (res.data.status === "ok") {
        const {
          data: { data },
        } = res;
        const mappedData = Object.keys(data).map((key) => data[key]);
        setAllGames(mappedData || []);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initGameTypeData = async () => {
    try {
      const res = await getAllGameTypes();
      if (res.data) {
        const mappedAvailableGameTypes = Object.keys(res.data).map(
          (key) => res.data[key]
        );
        setAvailableGameTypes(mappedAvailableGameTypes || []);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initGameListData();
    initGameTypeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <TopMenu
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        availableGameTypes={availableGameTypes}
      />
      <section className="main__body__area">
        <div className="container">
          <div className="row g-0">
            <div className="casino__tab__area">
              <div className="tab-content" id="nav-tabCcasino">
                {selectedTab === TopMenuTab.All && (
                  <div
                    className="tab-pane active show mt__30 pb-80 fade text-white"
                    id="casinot8"
                    role="tabpanel"
                    aria-labelledby="casinot1"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-top" />
                      </span>
                      <span>Top Games</span>
                    </div>
                    <RowGame items={Array(3).fill(0)} />
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-new" />
                      </span>
                      <span>New Games</span>
                    </div>
                    <RowGame items={Array(3).fill(0)} />
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-popular" />
                      </span>
                      <span>Popular Games</span>
                    </div>
                    <RowGame items={Array(3).fill(0)} />
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-slots" />
                      </span>
                      <span>Slots</span>
                    </div>
                    <RowGame hasFirstBigItem={false} items={Array(8).fill(0)} />
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-jackpot" />
                      </span>
                      <span>Jackpots</span>
                    </div>
                    <RowGame hasFirstBigItem={false} items={Array(8).fill(0)} />

                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-live" />
                      </span>
                      <span>Live Games</span>
                    </div>
                    <RowGame
                      hasFirstBigItem={false}
                      items={Array(8).fill(0)}
                      hasLiveBadge={true}
                    />
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-game" />
                      </span>
                      <span>All Games</span>
                    </div>
                    <RowGame
                      hasFirstBigItem={false}
                      items={Array(20).fill(0)}
                    />
                  </div>
                )}
                {selectedTab === TopMenuTab.Top && (
                  <div
                    className="tab-pane active show fade mt__30 text-white"
                    id="casinot1"
                    role="tabpanel"
                    aria-labelledby="casinot1"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-top" />
                      </span>
                      <span>Top Games</span>
                    </div>
                    <RowGame items={Array(4).fill(0)} />
                  </div>
                )}
                {selectedTab === TopMenuTab.New && (
                  <div
                    className="tab-pane active show fade text-white mt__30"
                    id="casinot2"
                    role="tabpanel"
                    aria-labelledby="casinot2"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-twer" />
                      </span>
                      <span>New</span>
                    </div>
                    <RowGame items={Array(8).fill(0)} />
                  </div>
                )}
                {selectedTab === TopMenuTab.Popular && (
                  <div
                    className="tab-pane active show fade text-white mt__30"
                    id="casinot3"
                    role="tabpanel"
                    aria-labelledby="casinot3"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-popular" />
                      </span>
                      <span>Popular</span>
                    </div>
                    <RowGame hasFirstBigItem={false} />
                  </div>
                )}
                {selectedTab === TopMenuTab.Slots && (
                  <div
                    className="tab-pane active show fade text-white mt__30"
                    id="casinot4"
                    role="tabpanel"
                    aria-labelledby="casinot4"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-slots" />
                      </span>
                      <span>Slots</span>
                    </div>
                    <RowGame
                      hasFirstBigItem={false}
                      items={Array(20).fill(0)}
                    />
                  </div>
                )}
                {selectedTab === TopMenuTab.TableGames && (
                  <div
                    className="tab-pane active show fade text-white mt__30"
                    id="casinot5"
                    role="tabpanel"
                    aria-labelledby="casinot5"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-pmart" />
                      </span>
                      <span>Table Games</span>
                    </div>
                    <RowGame
                      items={Array(20).fill(0)}
                      hasFirstBigItem={false}
                    />
                  </div>
                )}
                {selectedTab === TopMenuTab.Jackpots && (
                  <div
                    className="tab-pane active show fade text-white mt__30"
                    id="casinot6"
                    role="tabpanel"
                    aria-labelledby="casinot6"
                  >
                    <div className="common__head mb__30">
                      <span className="icons">
                        <i className="icon-jackpot" />
                      </span>
                      <span>Jackpots</span>
                    </div>
                    <RowGame
                      items={Array(20).fill(0)}
                      hasFirstBigItem={false}
                    />
                  </div>
                )}
                <div
                  className="tab-pane fade show active text-white mt__30"
                  id="casinot7"
                  role="tabpanel"
                  aria-labelledby="casinot7"
                >
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-live" />
                    </span>
                    <span>Top Games</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(4).fill(0)}
                    hasFirstBigItem={false}
                  />
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-game" />
                    </span>
                    <span>New Games</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(8).fill(0)}
                    hasFirstBigItem={false}
                  />
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-popular" />
                    </span>
                    <span>Popular Games</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(4).fill(0)}
                    hasFirstBigItem={false}
                  />
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-slots" />
                    </span>
                    <span>Slots</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(8).fill(0)}
                    hasFirstBigItem={false}
                  />
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-jackpot" />
                    </span>
                    <span>Jackpots</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(8).fill(0)}
                    hasFirstBigItem={false}
                  />
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-live" />
                    </span>
                    <span>Live Games</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(8).fill(0)}
                    hasFirstBigItem={false}
                  />
                  <div className="common__head mb__30">
                    <span className="icons">
                      <i className="icon-game" />
                    </span>
                    <span>All Games</span>
                  </div>
                  <RowGame
                    hasLiveBadge={true}
                    items={Array(8).fill(0)}
                    hasFirstBigItem={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </section>
    </div>
  );
};

export default LiveCasino;
