import { useState } from "react";
import { TopMenuItems } from "../constants";
import { TopMenuTab } from "../types/TopMenuTab";
import classNames from "classnames";

type TopMenuProps = {
  selectedTab: TopMenuTab;
  setSelectedTab: (tab: TopMenuTab) => void;
  availableGameTypes?: TopMenuTab[];
};

const TopMenu = ({
  selectedTab,
  setSelectedTab,
  availableGameTypes = [],
}: TopMenuProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const menuItems = TopMenuItems.filter((x) =>
    availableGameTypes.includes(x.name)
  );
  menuItems.unshift(TopMenuItems[0]); // All Games
  menuItems.push(TopMenuItems[TopMenuItems.length - 1]); // Search

  return (
    <section className="main__tab__slide">
      <ul className="nav nav-tabs" id="myTabmain" role="tablist">
        {menuItems.map((tab) => {
          if (tab.name === TopMenuTab.Search) {
            return (
              <li className="nav-item" key={tab.id}>
                <div className="search-button">
                  <button
                    className="nav-link"
                    id="search"
                    type="button"
                    onClick={() => setShowSearch(true)}
                  >
                    <span className="icons">
                      <i className="icon-search" />
                    </span>
                    <span>Search</span>
                  </button>
                  <div
                    className={classNames(
                      "search-popup",
                      showSearch && "!block"
                    )}
                  >
                    <div
                      className="search-bg"
                      onClick={() => setShowSearch(false)}
                    />
                    <div
                      className={classNames(
                        "search-form transition-all duration-50 ease-out",
                        showSearch && "!right-0"
                      )}
                    >
                      <form action="#">
                        <div className="form">
                          <input
                            type="text"
                            id="searchs"
                            placeholder="Search Your Fovatires Game"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </li>
            );
          }
          return (
            <li className="nav-item" role="presentation" key={tab.id}>
              <button
                className={`nav-link ${
                  selectedTab === tab.name ? "active" : ""
                }`}
                id={`main-tab${tab}`}
                data-bs-toggle="tab"
                data-bs-target={`#casinot${tab}`}
                type="button"
                role="tab"
                aria-selected={selectedTab === tab.name}
                onClick={() => setSelectedTab(tab.name)}
              >
                <span className="icons">
                  <i className={tab.icon} />
                </span>
                <span>{tab.name}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default TopMenu;
