import { ReactNode } from "react";
import useAuthStore from "../../hooks/authStore";
import { useNavigate } from "react-router-dom";

type Props = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  children: ReactNode;
};

export const DashboardSideBar = ({
  selectedTab,
  setSelectedTab,
  children,
}: Props) => {
  const { setUser } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("email");
    setUser(undefined);
    navigate("/");
  };

  return (
    <div>
      <div>
        <section className="dashboard__body mt__30 pb-60">
          <div className="container">
            <div className="row g-4">
              <div className="col-xxl-3 col-xl-3 col-lg-4">
                <div className="dashboard__side__bar">
                  <ul className="account__menu">
                    <li>
                      <span
                        className={
                          selectedTab === "Account Settings"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Account Settings")}
                      >
                        <span className="icons">
                          <i className="icon-user" />
                        </span>
                        <span>Account Settings</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Contact Preferences"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Contact Preferences")}
                      >
                        <span className="icons">
                          <i className="icon-pcontact" />
                        </span>
                        <span>Contact Preferences</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Identity Details"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Identity Details")}
                      >
                        <span className="icons">
                          <i className="icon-details" />
                        </span>
                        <span>Identity Details</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Verification"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Verification")}
                      >
                        <span className="icons">
                          <i className="icon-verify" />
                        </span>
                        <span>Verify</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Casino Bets"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Casino Bets")}
                      >
                        <span className="icons">
                          <i className="icon-casino" />
                        </span>
                        <span>Casino bets</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "My Promo"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("My Promo")}
                      >
                        <span className="icons">
                          <i className="icon-promos" />
                        </span>
                        <span>My Promos</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Deposit"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Deposit")}
                      >
                        <span className="icons">
                          <i className="icon-deposit" />
                        </span>
                        <span>Deposit</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Withdraw"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Withdraw")}
                      >
                        <span className="icons">
                          <i className="icon-withdraw" />
                        </span>
                        <span>Withdraw</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Transaction History"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Transaction History")}
                      >
                        <span className="icons">
                          <i className="icon-history" />
                        </span>
                        <span>Transction History</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Notification"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Notification")}
                      >
                        <span className="icons">
                          <i className="icon-notifivation" />
                        </span>
                        <span>Notifications</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Bet History"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Bet History")}
                      >
                        <span className="icons">
                          <i className="icon-bhistory" />
                        </span>
                        <span>Bet History</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className={
                          selectedTab === "Bonuses"
                            ? "dashboard-side-bar-menus active"
                            : "dashboard-side-bar-menus"
                        }
                        onClick={() => setSelectedTab("Bonuses")}
                      >
                        <span className="icons">
                          <i className="icon-bonus" />
                        </span>
                        <span>Bonuses</span>
                      </span>
                    </li>
                    <li>
                      <span
                        className="dashboard-side-bar-menus bg-transparent hover:bg-transparent"
                        onClick={handleLogout}
                      >
                        <span className="icons">
                          <i className="icon-logout" />
                        </span>
                        <span>Logout</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
