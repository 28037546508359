import footerLogo from "../../img/logo/footerlogo.png";
import rightArrow from "../../img/footer/rightarrow.png";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Footer = () => {
  return (
    <div>
      <footer className="footer__section pt-60">
        <div className="container">
          <div className="footer__top pb-60">
            <div className="row g-5">
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
                data-wow-delay="0.9s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.9s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="widget__items">
                  <div className="footer-head">
                    <a href="#0" className="footer-logo">
                      <img src={footerLogo} alt="f-logo" />
                    </a>
                  </div>
                  <div className="content-area">
                    <p>
                      Lorem ipsum dolor sit of the cart amet, consectetur
                      adipiscing elit. I talk out of the moon.
                    </p>
                    <h6>Follow Us</h6>
                    <ul className="social">
                      <li>
                        <FontAwesomeIcon icon={faFacebookF} size={"lg"} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faInstagram} size={"lg"} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faLinkedinIn} size={"lg"} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faTwitter} size={"lg"} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xxl-3 col-xl-2 col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
                data-wow-delay="0.7s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.7s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="widget__items">
                  <div className="footer-head">
                    <h3 className="title">Company</h3>
                  </div>
                  <div className="content-area">
                    <ul className="quick-link">
                      <li>
                        <a href="index.html">
                          <img src={rightArrow} alt="angle" /> Home
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <img src={rightArrow} alt="angle" /> Slots
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <img src={rightArrow} alt="angle" /> Tournament
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <img src={rightArrow} alt="angle" /> Jackpots
                        </a>
                      </li>
                      <li>
                        <a href="livecasino.html">
                          <img src={rightArrow} alt="angle" /> Live Games
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-3 wow fadeInUp"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="widget__items">
                  <div className="footer-head">
                    <h3 className="title">Support</h3>
                  </div>
                  <div className="content-area">
                    <ul className="quick-link">
                      <li>
                        <a href="#0">
                          <img src={rightArrow} alt="angle" /> Faqs
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <img src={rightArrow} alt="angle" /> Support
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xxl-4 col-xl-5 col-lg-4 col-md-6 col-sm-9 wow fadeInUp"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="widget__items">
                  <div className="footer-head">
                    <h3 className="title">Subscribe Our Newslatter</h3>
                  </div>

                  <p>
                    Proin mauris ligula, pretium eu est ut, imperdiet imperdiet
                    massa. Nullam sodales ut orci vehicula aliquam. Suspendisse.
                  </p>
                  <div className="content-area">
                    <form action="#0">
                      <input
                        type="text"
                        placeholder="Enter Your Email address"
                      />
                      <button className="cmn--btn" type="submit">
                        <span>Subscribe</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <p className="text-white">
              Copyright &copy; 2023,{" "}
              <a href="#0" className="text--base">
                SportOdds
              </a>{" "}
              - All Right Reserved
            </p>
            <ul className="bottom__ling">
              <li>
                <a href="#0" className="text-white">
                  Affiliate program
                </a>
              </li>
              <li>
                <a href="#0" className="text-white">
                  Terms & conditions
                </a>
              </li>
              <li>
                <a href="#0" className="text-white">
                  Bonus terms & conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};
