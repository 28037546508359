import { useState } from "react";
import promo1 from "../../../img/profile/promo3.jpg";
import promo2 from "../../../img/profile/promo3.jpg";
import promo3 from "../../../img/profile/promo3.jpg";

const MyPromo = () => {
  const [activeTab, setActiveTab] = useState("offers");

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  // const renderTabContent = () => {
  //   switch (activeTab) {
  //     case "offers":
  //       return (
  //         <div
  //           className="tab-pane fade show active"
  //           id="protab1"
  //           role="tabpanel"
  //           aria-labelledby="protab1"
  //         >
  //           {/* Content for Offers tab */}
  //         </div>
  //       );
  //     case "inProgress":
  //       return (
  //         <div
  //           className="tab-pane fade"
  //           id="protab2"
  //           role="tabpanel"
  //           aria-labelledby="protab2"
  //         >
  //           {/* Content for In Progress tab */}
  //         </div>
  //       );
  //     case "used":
  //       return (
  //         <div
  //           className="tab-pane fade"
  //           id="protab3"
  //           role="tabpanel"
  //           aria-labelledby="protab3"
  //         >
  //           {/* Content for Used tab */}
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">My Promos</h3>
        <div className="promocode__wrap">
          <h3>Got a Promo Code?</h3>
          <form action="#0">
            <input type="text" />
            <a href="#0" className="cmn--btn">
              <span>Check</span>
            </a>
          </form>
        </div>
      </div>
      <div className="promor__tab">
        <ul className="nav nav-tabs" id="myTabpro" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "offers" ? "active" : ""}`}
              onClick={() => handleTabChange("offers")}
              data-bs-toggle="tab"
              data-bs-target="#protab1"
              type="button"
              role="tab"
              aria-controls="protab1"
              aria-selected="true"
            >
              Offers 3
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "inProgress" ? "active" : ""
                }`}
              onClick={() => handleTabChange("inProgress")}
              data-bs-toggle="tab"
              data-bs-target="#protab2"
              type="button"
              role="tab"
              aria-controls="protab2"
              aria-selected="false"
            >
              In Progress 0
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "used" ? "active" : ""}`}
              onClick={() => handleTabChange("used")}
              data-bs-toggle="tab"
              data-bs-target="#protab3"
              type="button"
              role="tab"
              aria-controls="protab3"
              aria-selected="false"
            >
              Used
            </button>
          </li>
        </ul>
      </div>
      <div className="promo__tab__body">
        <div className="tab-content" id="myTabContentpro">
          <div
            className="tab-pane fade show active"
            id="protab1"
            role="tabpanel"
            aria-labelledby="protab1"
          >
            <div className="promomy__items mb__30">
              <div className="thumb">
                <img src={promo1} alt="img" />
              </div>
              <div className="content">
                <h4>Welcome Bonus</h4>
                <p>
                  Get 100% up to $100 as a bonus on your first deposit!Get 100%
                  up to $100 as a bonus on your first deposit!
                </p>
                <span className="smalltext">Use code at deposit</span>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
            <div className="promomy__items mb__30">
              <div className="thumb">
                <img src={promo2} alt="img" />
              </div>
              <div className="content">
                <h4>Welcome Bonus</h4>
                <p>
                  Get 100% up to $300 as a bonus on your first deposit!Get 100%
                  up to $100 as a bonus on your first deposit!
                </p>
                <span className="smalltext">Use code at deposit</span>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
            <div className="promomy__items">
              <div className="thumb">
                <img src={promo3} alt="img" />
              </div>
              <div className="content">
                <h4>Welcome Bonus</h4>
                <p>
                  Get 100% up to $600 as a bonus on your first deposit!Get 100%
                  up to $100 as a bonus on your first deposit!
                </p>
                <span className="smalltext">Use code at deposit</span>
                <a href="#0" className="cmn--btn">
                  <span>WELCOME100</span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="protab2"
            role="tabpanel"
            aria-labelledby="protab2"
          ></div>
          <div
            className="tab-pane fade"
            id="protab3"
            role="tabpanel"
            aria-labelledby="protab3"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MyPromo;
