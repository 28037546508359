import { TopMenuItem, TopMenuTab } from "../types/TopMenuTab";

export const TopMenuItems: TopMenuItem[] = [
  {
    id: 1,
    name: TopMenuTab.All,
    icon: "icon-game",
  },
  // {
  //   id: 2,
  //   name: TopMenuTab.Top,
  //   icon: "icon-top",
  // },
  // {
  //   id: 3,
  //   name: TopMenuTab.New,
  //   icon: "icon-new",
  // },
  // {
  //   id: 4,
  //   name: TopMenuTab.Popular,
  //   icon: "icon-popular",
  // },
  {
    id: 5,
    name: TopMenuTab.Slots,
    icon: "icon-slots",
  },
  {
    id: 6,
    name: TopMenuTab.TableGames,
    icon: "icon-ttennis",
  },
  // {
  //   id: 7,
  //   name: TopMenuTab.Jackpots,
  //   icon: "icon-jackpot",
  // },
  // {
  //   id: 8,
  //   name: TopMenuTab.LiveGames,
  //   icon: "icon-live",
  // },
  {
    id: 9,
    name: TopMenuTab.Instant,
    icon: "icon-live",
  },
  {
    id: 10,
    name: TopMenuTab.Search,
    icon: "icon-search",
  },
];

export const NavItems = [
  // {
  //   name: "Live",
  //   link: "",
  // },
  {
    name: "Sports Betting",
    link: "/sport-betting",
  },
  {
    name: "Casino",
    link: "/",
  },
  // {
  //   name: "Lucky Drops",
  //   link: "",
  // },
  {
    name: "Live Casino",
    link: "/live-casino",
  },
  {
    name: "Promotions",
    link: "/promotion",
  },
];
