import { useState } from "react";
import { DashboardSideBar } from "./DashboardSideBar";
import { AccountSettings } from "./view/AccountSettings";
import { ContactPreference } from "./view/ContactPreference";
import IdentityDetails from "./view/IdentityDetails";
import Verification from "./view/Verification";
import CasinoBets from "./view/CasinoBets";
import MyPromo from "./view/MyPromo";
import Deposit from "./view/Deposit";
import Withdraw from "./view/Withdraw";
import TransactionHistory from "./view/TransactionHistory";
import Notification from "./view/Notification";
import BetHistory from "./view/BetHistory";
import Bonuses from "./view/Bonuses";

export const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState("Account Settings");

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Account Settings":
        return <AccountSettings />;
      case "Contact Preferences":
        return <ContactPreference />;
      case "Identity Details":
        return <IdentityDetails />;
      case "Verification":
        return <Verification />;
      case "Casino Bets":
        return <CasinoBets />;
      case "My Promo":
        return <MyPromo />;
      case "Deposit":
        return <Deposit />;
      case "Withdraw":
        return <Withdraw />;
      case "Transaction History":
        return <TransactionHistory />;
      case "Notification":
        return <Notification />;
      case "Bet History":
        return <BetHistory />;
      case "Bonuses":
        return <Bonuses />;
      default:
        return <AccountSettings />;
    }
  };

  return (
    <div>
      <DashboardSideBar
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      >
        {renderTabContent()}
      </DashboardSideBar>
    </div>
  );
};
