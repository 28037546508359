export enum ESignInTab {
  SignIn = "Sign In",
  SignUp = "Sign Up",
}

export type SignUpDto = {
  email: string;
  currency: string;
  phoneNumber: string;
  country: string;
  locale: string;
  sex: string;
  marketingTag: string;
  password: string;
  consent: boolean;
  city: string;
  address: string;
  referral?: string;
  bonusCode?: string;
  firstName: string;
  lastName: string;
  dob: string;
  username: string;
};

export type SignInDto = {
  email: string;
  password: string;
  meta: {
    ip: string;
    country: string;
  };
};
