import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../img/logo/logo.png";
import { useState } from "react";
import SignInModal from "../SignInModal";
import { ESignInTab } from "../../types/SignInModal";
import { NavItems } from "../../constants";
import useAuthStore from "../../hooks/authStore";
import { WalletBalance } from "./WalletBalance";

export const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [showModal, setShowModal] = useState(false);
  const [initialTab, setInitialTab] = useState(ESignInTab.SignIn);
  const { user } = useAuthStore();

  return (
    <div className="nav_wrapper">
      <header className="header-section dashboard__header">
        <div className="container p-0">
          <div className="header-wrapper">
            <div className="menu__left__wrap">
              <div className="logo-menu px-2">
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="logo"
                >
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <ul className="main-menu">
                {NavItems.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        item.link?.length && navigate(item.link);
                      }}
                      className={currentPath === item.link ? "active" : ""}
                    >
                      <span>{item.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>

            {user ? (
              <div className="mneu-btn-grp">
                <WalletBalance accounts={user.accounts} />
                <div
                  className="cmn--btn text-white cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/profile");
                  }}
                >
                  <span>Profile Dashboard</span>
                </div>
              </div>
            ) : (
              <div className="mneu-btn-grp">
                <div
                  className="cmn--btn text-white cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setInitialTab(ESignInTab.SignIn);
                    setShowModal(true);
                  }}
                >
                  <span>Sign In</span>
                </div>
                <div
                  className="cmn--btn2 text-white cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setInitialTab(ESignInTab.SignUp);
                    setShowModal(true);
                  }}
                >
                  <span className="rela">Sign Up</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </header >
      <SignInModal
        isShowing={showModal}
        setIsShowing={setShowModal}
        initialTab={initialTab}
      />
    </div >
  );
};
