import { create } from "zustand";
import { AuthObject, User } from "../types/auth";

interface AuthState {
  user?: User;
  setUser: (user?: User) => void;
  authObject?: AuthObject;
  setAuthObject: (authObject?: AuthObject) => void;
}

const useAuthStore = create<AuthState>((set) => ({
  user: undefined,
  setUser: (user?: User) => set({ user }),
  authObject: undefined,
  setAuthObject: (authObject?: AuthObject) => set({ authObject }),
}));

export default useAuthStore;
