import visa from "../../../img/profile/visa.png";
import webpay from "../../../img/profile/webpay.png";
import skrill from "../../../img/profile/skrill.png";
import much from "../../../img/profile/much.png";
import neteller from "../../../img/profile/neteller.png";
import visas from "../../../img/profile/visas.png";
import paypal from "../../../img/profile/paypal.png";
import bitcoin from "../../../img/profile/bitcoin.png";
import inovarav from "../../../img/profile/inovarav.png";
import phonerpe from "../../../img/profile/phonerpe.png";
import coins from "../../../img/profile/coins.png";
import yandex from "../../../img/profile/yandex.png";
import visap from "../../../img/profile/visap.png";
import bvisa from "../../../img/profile/bvisa.png";

const Deposit = () => {
  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Deposit</h3>
        <div className="payment__cart__check">
          <div className="row g-4">
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit1"
                />
                <label className="form-check-label" htmlFor="deposit1">
                  <img src={visa} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit2"
                />
                <label className="form-check-label" htmlFor="deposit2">
                  <img src={webpay} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit3"
                />
                <label className="form-check-label" htmlFor="deposit3">
                  <img src={skrill} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit4"
                />
                <label className="form-check-label" htmlFor="deposit4">
                  <img src={much} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit5"
                />
                <label className="form-check-label" htmlFor="deposit5">
                  <img src={neteller} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit6"
                />
                <label className="form-check-label" htmlFor="deposit6">
                  <img src={paypal} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit7"
                />
                <label className="form-check-label" htmlFor="deposit7">
                  <img src={bitcoin} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit8"
                />
                <label className="form-check-label" htmlFor="deposit8">
                  <img src={inovarav} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit9"
                />
                <label className="form-check-label" htmlFor="deposit9">
                  <img src={phonerpe} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit10"
                />
                <label className="form-check-label" htmlFor="deposit10">
                  <img src={coins} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit11"
                />
                <label className="form-check-label" htmlFor="deposit11">
                  <img src={yandex} alt="visa" />
                </label>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="payment__cart__items">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deposit12"
                />
                <label className="form-check-label" htmlFor="deposit12">
                  <img src={visas} alt="visa" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="deposit__complate">
          <h3>Complete Your Deposit</h3>
          <div className="deposit__wallet">
            <div className="deopsit__wallet__items">
              <p>Deposit to Wallet</p>
              <div className="usd__chacnge">
                <span>USD</span>
                <div className="icons" id="profile-picture">
                  <i className="fas fa-pen" />
                </div>
              </div>
            </div>
            <div className="deopsit__wallet__items">
              <p>Payment Provider</p>
              <div className="usd__chacnge">
                <span>
                  <img src={bvisa} alt="vissa" />
                </span>
                <div className="icons" id="profile-picture2">
                  <i className="fas fa-pen" />
                </div>
              </div>
            </div>
          </div>
          <div className="promo__code">
            <span className="promo">Promo Code</span>
            <a href="#0">
              <span>Enter Code</span>
              <span>
                <i className="fas fa-plus" />
              </span>
            </a>
          </div>
          <a href="#0" className="visa__card">
            <img src={visap} alt="visa" />
          </a>
          <ul className="quick-value">
            <li>
              <h5 className="active">20</h5>
            </li>
            <li>
              <h5>30</h5>
            </li>
            <li>
              <h5>100</h5>
            </li>
            <li>
              <a href="#0">Custom</a>
            </li>
          </ul>
          <form action="#">
            <div className="single-input mb__20">
              <input
                type="text"
                id="dAmount"
                placeholder="Enter Amount"
                value="$20.00"
                autoComplete="off"
              />
            </div>
            <div className="single-input">
              <input
                type="text"
                id="eemail"
                placeholder="Email"
                autoComplete="off"
              />
            </div>
            <div className="total__amount">
              <div className="items">
                <span>Amount Fee</span>
                <span>$20</span>
              </div>
              <div className="items">
                <span>Total</span>
                <span>$20</span>
              </div>
            </div>
            <div className="btn-area">
              <button className="cmn--btn" type="button">
                <span>Deposit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
