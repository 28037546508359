import playImg from "../img/games/play.png";
import BigPlaceholderImage from "../img/games/306x420-placeholder.jpg";
import PlaceHolderImage from "../img/games/306x198-placeholder.jpg";

const Item = ({
  isFirstBig = false,
  hasLiveBadge = false,
  item = {},
}: {
  isFirstBig?: boolean;
  hasLiveBadge?: boolean;
  item?: any;
}) => {
  const { gameCode = "" } = item;
  let imgSrc = isFirstBig ? BigPlaceholderImage : PlaceHolderImage;
  if (gameCode) {
    imgSrc = `https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Games/${gameCode}/Components/icon.jpg`;
  }
  return (
    <div className="casino__box h-full">
      <img src={imgSrc} alt="game" className="h-full" />
      {hasLiveBadge && (
        <a href="#0" className="live">
          Live
        </a>
      )}
      <div className="casino__overlay">
        <a
          href="https://www.youtube.com/watch?v=orf582WJV_c"
          className="play-btn"
        >
          <img src={playImg} alt="img" />
        </a>
      </div>
    </div>
  );
};

const RowGame = ({
  hasFirstBigItem = true,
  hasLiveBadge = false,
  items = [],
}: {
  hasFirstBigItem?: boolean;
  hasLiveBadge?: boolean;
  items?: any;
}) => {
  if (!hasFirstBigItem) {
    return (
      <div className="row g-4 pb-60">
        {items.map((item: any, index: number) => {
          return (
            <div
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6"
              key={index}
            >
              <Item
                isFirstBig={false}
                hasLiveBadge={hasLiveBadge}
                item={item}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="row g-4 pb-60">
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
        <Item isFirstBig={true} hasLiveBadge={hasLiveBadge} />
      </div>
      {items.map((item: any, index: number) => {
        return (
          <div
            className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6"
            key={index}
          >
            <div className="casino__box__dobble">
              <Item
                isFirstBig={false}
                hasLiveBadge={hasLiveBadge}
                item={item}
              />
              <Item
                isFirstBig={false}
                hasLiveBadge={hasLiveBadge}
                item={item}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RowGame;
