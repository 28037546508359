import axios from "axios";
import getConfig from "../config/getConfig";


const microKycClient = axios.create({
    baseURL: `${getConfig().API_URL_KYC}`,
});

export const getKycByPlayer = async (playerId: string) => {
    return microKycClient.get("/kyc/get_by_player", {
        params: {
            uuid: playerId,
            isLatest: true
        }
    });
};

export const getKycExpirationStatus = async (playerId: string) => {
    return microKycClient.get("/kyc/kyc_expiration_status", {
        params: {
            uuid: playerId,
            isLatest: true
        }
    });
};

export const uploadKyc = async (playerId: string, payloadFormData: any) => {
    return microKycClient.post(`/kyc/upload/${playerId}`, payloadFormData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
}

export const updateKYCDocument = async (documentType: string, playerId: string, payloadFormData: any) => {
    return microKycClient.put(`/kyc/update/${documentType}/${playerId}`, payloadFormData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
}