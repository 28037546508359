import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClock, faEye, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getKycExpirationStatus, updateKYCDocument, uploadKyc } from "../../../services/kyc";
import { getSessionItem } from "../../../helpers/sessionStorage";
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

interface VerificationArgs {
  verificationType: string;
  verificationLabel: string;
  handleSetSelectedFilesByType: (type: string, file: any) => void;
  documentList: any;
  allSelectedFiles: any;
  uploadedData: any;
  callUpdateKYCDocument: (documentType: string) => void;
}

const verificationTypeToApprovedKey: any = {
  "ID": "idApproved",
  "ADDRESS": "addressApproved",
  "ID_WITH_PHOTO": "photoWithIdApproved"
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px"
  },
  button: {
    width: "200px",
    padding: "10px 20px",
    fontSize: "16px",
  },
  attachedStatusDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    marginTop: '0.4rem',
    color: 'white',
    gap: '0.5rem'
  }
};

const verificationBoxDetails = [
  {
    verificationType: "ID",
    verificationLabel: "Identification",
    documentList: ['Photo ID', 'Passport', 'ID Card']
  },
  {
    verificationType: "ADDRESS",
    verificationLabel: "Address",
    documentList: ['Utility Bill']
  },
  {

    verificationType: "ID_WITH_PHOTO",
    verificationLabel: 'ID with Photo',
    documentList: ['Selfie with ID next to face', 'Selfie with passport next to face']
  }
]

const FILE_APPROVAL_STATUS = {
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  EXPIRED: "EXPIRED"
}

const Verification = () => {
  const [uploadedData, setUploadedData]: any = useState(null)
  const [allSelectedFiles, setAllSelectedFiles]: any = useState({
    "ID": null,
    "ADDRESS": null,
    "ID_WITH_PHOTO": null
  })

  const isKYCSubmitted = useMemo(() => {
    return uploadedData && (uploadedData?.documents.length > 0)
  }, [uploadedData])

  const kycStatus = useMemo(() => {
    let kycStatus = "Verification Pending"
    if (uploadedData && uploadedData.approved) {
      kycStatus = "Verified"
    }
    return kycStatus
  }, [uploadedData])

  const getPlayerKycDetails = async () => {
    const playerId = getSessionItem("playerId")
    try {
      const response = await getKycExpirationStatus(playerId as string)
      setUploadedData(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const uploadKYCDocuments = async () => {
    const playerId = getSessionItem("playerId")
    try {
      const payloadFormData = new FormData()
      Object.keys(allSelectedFiles).forEach((fileKey: string) => {
        if (!(allSelectedFiles[fileKey])) {
          throw new Error(`File ${fileKey} not present`)
        }
        payloadFormData.append(fileKey, allSelectedFiles[fileKey])
      })
      const response = await uploadKyc(playerId as string, payloadFormData)
      setUploadedData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const callUpdateKYCDocument = async (documentType: string) => {
    const playerId = getSessionItem("playerId")
    try {
      const payloadFormData = new FormData()
      payloadFormData.append('document', allSelectedFiles[documentType])
      const response = await updateKYCDocument(documentType, playerId as string, payloadFormData)
      setUploadedData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSetSelectedFilesByType = (type: string, file: any) => {
    setAllSelectedFiles((prev: any) => ({
      ...prev,
      [type]: file
    }))
  }

  useEffect(() => {
    getPlayerKycDetails()
  }, [])

  const verificationBoxes = () => {
    return (
      <div className="row g-4">
        {
          verificationBoxDetails.map((detail: any) => {
            return (
              <VerificationBox
                key={detail.verificationType}
                verificationType={detail.verificationType}
                verificationLabel={detail.verificationLabel}
                documentList={detail.documentList}
                uploadedData={uploadedData}
                allSelectedFiles={allSelectedFiles}
                handleSetSelectedFilesByType={handleSetSelectedFilesByType}
                callUpdateKYCDocument={callUpdateKYCDocument}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Verification</h3>
        <div className="verification__wrap">
          {verificationBoxes()}
        </div>
        <div style={styles.container}>
          <button disabled={isKYCSubmitted} style={styles.button} className="cmn--btn" type="button" onClick={uploadKYCDocuments}>
            <span>{isKYCSubmitted ? kycStatus : "Submit"}</span>
          </button>
        </div>
        <div className="bottom__text pt-120">
          <h4>Additional information</h4>
          <p>
            The verification procedure usually takes up to 48 hours from the
            moment of successful download and receiving of client’s data.
            However, we always try to process information as soon as possible.
            As soon as an account is verified, you will receive an email
            notification. You can also contact customer support (online chat or
            by phone) to clarify this information.
          </p>
        </div>
      </div>
    </div >
  );
};

// Component for a single Verification Box
const VerificationBox = ({
  verificationType,
  verificationLabel,
  handleSetSelectedFilesByType,
  documentList,
  allSelectedFiles,
  uploadedData,
  callUpdateKYCDocument
}: VerificationArgs) => {
  const fileInputId = `file-input-${verificationType}`
  const [selectedDocumentType, setSelectedDocumentType] = useState(documentList[0]);
  const [showReUpload, setShowReupload] = useState(false)

  const selectedFile = useMemo(() => {
    return allSelectedFiles?.[verificationType]
  }, [allSelectedFiles, verificationType])

  const uploadedDataWithSelectedDocument = useMemo(() => {
    if (uploadedData) {
      return {
        ...uploadedData,
        document: uploadedData?.documents.find((document: any) => {
          return (document.name === verificationType)
        })
      }
    }

    return null
  }, [uploadedData, verificationType])

  const fileDetails = useMemo(() => {
    if (uploadedDataWithSelectedDocument?.document) {
      return uploadedDataWithSelectedDocument?.document.files[0]
    }
    return null
  }, [uploadedDataWithSelectedDocument])

  const fileApprovalStatus = useMemo(() => {
    let status = {
      key: FILE_APPROVAL_STATUS.PENDING,
      label: "Review Pending",
      iconColor: "yellow",
      icon: faClock
    }
    const _documentDetails = uploadedDataWithSelectedDocument?.document
    if (uploadedDataWithSelectedDocument && _documentDetails?.isReviewed) {
      if (_documentDetails?.rejected) {
        status = {
          key: FILE_APPROVAL_STATUS.REJECTED,
          label: `rejected due to ${_documentDetails.rejectionReason}`,
          iconColor: "red",
          icon: faTimesCircle
        }
      } else if (_documentDetails?.isExpired) {
        status = {
          key: FILE_APPROVAL_STATUS.EXPIRED,
          label: `expired on ${dayjs(_documentDetails?.expiryTime ?? dayjs())?.local()?.format('YYYY-MM-DD')}`,
          iconColor: "red",
          icon: faTimesCircle
        }
      } else if (uploadedDataWithSelectedDocument?.[verificationTypeToApprovedKey[verificationType]]) {
        status = {
          key: FILE_APPROVAL_STATUS.APPROVED,
          label: "approved",
          iconColor: "green",
          icon: faCheckCircle
        }
      }
    }
    return status
  }, [uploadedDataWithSelectedDocument, verificationType])

  // Handle document type selection
  const handleDocumentTypeChange = (e: any) => {
    handleSetSelectedFilesByType(verificationType, null)
    setSelectedDocumentType(e.target.value)
  }

  // Handle file selection
  const handleFileChange = (e: any) => {
    const file = e.target.files[0]
    handleSetSelectedFilesByType(verificationType, file)
  };

  // Trigger the file input click event
  const openFileDialog = () => {
    const fileInput: any = document.getElementById(`${fileInputId}`)
    fileInput.click();
  };

  const handleUpdateDocument = async () => {
    await callUpdateKYCDocument(verificationType)
    setShowReupload(false)
  }

  const showFileSelector = useMemo(() => {
    return (!fileDetails?.filePath) || showReUpload
  }, [fileDetails, showReUpload])

  return (
    <div className="col-xxl-6 col-xl-6 col-lg-12">
      <div className="verification__box">
        <h4 className="veri__title">{verificationLabel}</h4>
        {
          showFileSelector ? (
            <>
              <div className="attach__wrap">
                <select name={`${verificationType}`} id={`${verificationType}`} onChange={handleDocumentTypeChange}>
                  {
                    documentList.map((document: any, index: any) => {
                      return (
                        <option key={document} value={document}>
                          {document}
                        </option>
                      )
                    })
                  }
                </select>

                <input
                  id={`${fileInputId}`}
                  type="file"
                  accept={'.jpeg,.jpg,.png,.pdf'}
                  multiple={false}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />

                <button className="cmn--btn" type="button" onClick={openFileDialog}>
                  <span>Attach</span>
                </button>
              </div>
              {
                selectedFile ? (
                  <div>
                    <div style={styles.attachedStatusDiv}>
                      <span>
                        {selectedDocumentType} Attached
                      </span>
                      <span style={{ color: 'green' }}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    </div>
                    {
                      fileApprovalStatus.key === FILE_APPROVAL_STATUS.REJECTED || fileApprovalStatus.key === FILE_APPROVAL_STATUS.EXPIRED ? (
                        <div style={styles.attachedStatusDiv}>
                          <button className="cmn--btn" type="button" onClick={handleUpdateDocument}>
                            <span>Update Document</span>
                          </button>
                        </div>
                      ) : null
                    }
                  </div>
                ) : null
              }
            </>
          ) : (
            <div>
              <div style={styles.attachedStatusDiv}>
                <span>
                  File Uploaded
                </span>
                <span style={{ color: 'green' }}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
              </div>
              <div style={styles.attachedStatusDiv}>
                <a href={fileDetails?.filePath} target="_blank" rel="noopener noreferrer">
                  Preview file
                  <span style={{ marginLeft: "0.5rem", color: 'white' }}>
                    <FontAwesomeIcon icon={faEye} />
                  </span>
                </a>
              </div>
              <div style={styles.attachedStatusDiv}>
                <span>
                  Document {fileApprovalStatus.label}
                </span>
                <span style={{ marginLeft: "0.5rem", color: fileApprovalStatus.iconColor }}>
                  <FontAwesomeIcon icon={fileApprovalStatus.icon} />
                </span>
              </div>
              {
                fileApprovalStatus.key === FILE_APPROVAL_STATUS.REJECTED || fileApprovalStatus.key === FILE_APPROVAL_STATUS.EXPIRED ? (
                  <div style={styles.attachedStatusDiv}>
                    <button className="cmn--btn" type="button" onClick={() => setShowReupload(true)}>
                      <span>Attach New File</span>
                    </button>
                  </div>
                ) : null
              }
            </div>
          )
        }
      </div>
    </div >
  );
};

export default Verification;
