const IdentityDetails = () => {
  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Identity Details</h3>
        <div className="identity__details bgbox">
          <h5 className="identity__title">About You</h5>
          <div className="identity__left mb-5">
            <form action="#">
              <div className="row g-2 mb-3">
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>First Name (Given Name)</label>
                    <input type="text" id="name" placeholder="First Name" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>Last Name (Surname / Family Name)</label>
                    <input type="text" id="name2" placeholder="Last Name" />
                  </div>
                </div>
              </div>

              <div className="row g-2">
                <div className="col-lg-6">
                  <label>Date Of Birth</label>
                  <div className="row g-2">
                    <div className="col-lg-4">
                      <div className="form__box">
                        <input type="date" id="dob" placeholder="XX" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form__box">
                        <input type="date" placeholder="XX" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form__box">
                        <input type="date" placeholder="XXXX" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Phone Number</label>
                  <div className="row g-2">
                    <div className="col-lg-4">
                      <div className="form__box">
                        <input type="text" placeholder="+962" />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="form__box">
                        <input type="number" placeholder="XXXXXXXX" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <h5 className="identity__title">Address</h5>
          <div className="identity__left mb-3">
            <form action="#">
              <div className="row g-3">
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>Address</label>
                    <input type="text" id="add1" placeholder="XXXXX" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>Apt, Suite, etc</label>
                    <input type="text" id="add2" placeholder="XXXXX" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>City / Region</label>
                    <input type="text" id="add3" placeholder="XXXXX" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>Country</label>
                    <input type="text" id="add4" placeholder="United Kingdom" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>State / Province</label>
                    <input type="text" id="add5" placeholder="XXXXX" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form__box">
                    <label>Postal Code</label>
                    <input type="text" id="add6" placeholder="XXXX" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="authorize__wrap">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="aut1"
              checked
            />
            <label className="form-check-label">
              I authorize SportOdds to collect and transmit my personal
              information for identity verification or{" "}
              <span>similar uses as defined</span> in order to confirm my
              ability to use the website.
            </label>
          </div>
          <div className="save__btn mt-3 d-flex">
            <a href="#0" className="cmn--btn">
              <span>Save</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityDetails;
