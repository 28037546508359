import profile from "../../../img/profile/profile.png";
import vkonta from "../../../img/profile/vkonta.png";
import twitch from "../../../img/profile/twitch.png";
import facebook from "../../../img/profile/facebook.png";
import twitter from "../../../img/profile/twitter.png";
import steam from "../../../img/profile/steam.png";
import goggle from "../../../img/profile/goggle.png";
import plus from "../../../img/profile/plus.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export const AccountSettings = () => {
  return (
    <div className="col-xxl-9 col-xl-9 col-lg-8">
      <div className="dashboard__body__wrap">
        <h3 className="account__head mb__30">Account Settings</h3>
        <div className="row g-4">
          <div className="col-xl-4">
            <div className="user__box">
              <div className="img__change">
                <img src={profile} alt="profile" />
                <div className="icons" id="profile-picture">
                  <i>
                    <FontAwesomeIcon icon={faPen} />
                  </i>
                </div>
              </div>
              <div className="user__content">
                <h5 className="usertext__one">strdxc321</h5>
                <h6 className="usertext__two">UUID:</h6>
                <a href="#0" className="link">
                  ffbe99f2-7f4b-11ed-9e24-3ee8038fe302
                </a>
              </div>
              <div className="reset__wrap">
                <a href="#0" className="reset">
                  Reset Password
                </a>
                <a href="#0" className="change">
                  Change Gamertag
                </a>
              </div>
              <div className="user__dated">
                <span className="date">Joined December 19th, 2022</span>
                <a href="#0" className="lastlogin">
                  Last Login on
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="account__body">
              <div className="account__strength__box mb__30">
                <div className="strength__box">
                  <div className="circle__box">
                    <div className="circle">
                      <div className="box">
                        <h3 className="text">70%</h3>
                      </div>
                    </div>
                  </div>
                  <h5>Account Strength</h5>
                </div>
                <div className="strength__content">
                  <div className="items">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="stre1c"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="stre1c">
                      Create account
                    </label>
                  </div>
                  <div className="items">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="stre2"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="stre2">
                      Complete Account
                    </label>
                  </div>
                  <div className="items">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="stre3"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="stre3">
                      Verify Identity
                    </label>
                  </div>
                  <div className="items">
                    <span className="icons">
                      <i className="icon-deposit" />
                    </span>
                    <span>Made a Deposit</span>
                  </div>
                  <div className="items">
                    <span className="icons">
                      <i className="icon-user" />
                    </span>
                    <span>Upload Avatar</span>
                  </div>
                </div>
              </div>
              <div className="account__email mb__30">
                <h5>Account Emails</h5>
                <span className="subtitle">Primary Emails</span>
                <div className="form__wrap">
                  <form action="#">
                    <input type="text" placeholder="fixad56534@paxven.com" />
                    <i className="icon-lock" />
                  </form>
                  <div className="check__items">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="stre1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="stre1">
                      Verified
                    </label>
                  </div>
                </div>
                <a href="#0" className="add__email">
                  <span>
                    <img src={plus} alt="icon" />
                  </span>
                  <span>Add email</span>
                </a>
              </div>
              <div className="account__email enroll__box mb__30">
                <h5>Multi_factor Authentication</h5>
                <p>
                  Add an Extra Layer of security to your SportOdds account when
                  logging in with Email/Passsword. A verrification code will be
                  sent to your email each time you loin to secrely protect your
                  account.
                </p>
                <a href="#0" className="cmn--btn">
                  <span>Enroll</span>
                </a>
              </div>
              <div className="account__email language__box mb__30">
                <h5>Language</h5>
                <span className="slanguage">Select Language</span>
                <div className="language__wrap">
                  <select name="#" id="#id">
                    <option value="1">English</option>
                    <option value="2">Turki</option>
                    <option value="3">Spanish</option>
                  </select>
                  <a href="#0" className="cmn--btn">
                    <span>Save</span>
                  </a>
                </div>
              </div>
              <div className="account__email social__box mb__30">
                <h5>Social Accounts</h5>
                <span className="slanguage">
                  Connect your accounts for faster login.
                </span>
                <div className="social__wrap">
                  <div className="social__left">
                    <a href="#0">
                      <span>
                        <img src={goggle} alt="icon" />
                      </span>
                      <span>Connect Google</span>
                    </a>
                    <a href="#0">
                      <span>
                        <img src={steam} alt="icon" />
                      </span>
                      <span>Connect steam</span>
                    </a>
                    <a href="#0">
                      <span>
                        <img src={twitter} alt="icon" />
                      </span>
                      <span>Connect Twitter</span>
                    </a>
                  </div>
                  <div className="social__left">
                    <a href="#0">
                      <span>
                        <img src={facebook} alt="icon" />
                      </span>
                      <span>Connect facebook</span>
                    </a>
                    <a href="#0">
                      <span>
                        <img src={twitch} alt="icon" />
                      </span>
                      <span>Connect twitch</span>
                    </a>
                    <a href="#0">
                      <span>
                        <img src={vkonta} alt="icon" />
                      </span>
                      <span>Connect vkontakte</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="account__email enroll__box">
                <h5>Archive Account</h5>
                <p>Want to temporarily close your account?</p>
                <a href="#0" className="cmn--btn">
                  <span>Archive Account</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
