import { useEffect } from "react";

const SportBetting = () => {
  useEffect(() => {
    const bettingLoader = (window as any)?.bettingLoader;
    // const rootBettingEl = document.getElementById("testRoot");
    if (bettingLoader) {
      bettingLoader.load({
        token: "test",
        locale: "en-US",
        currency: "USD",
        rootElement: "testRoot",
        isAuthorized: false,
      });
    }
  });

  return (
    <div>
      Test
      <div id="testRoot" />
    </div>
  );
};

export default SportBetting;
