/**
 * Sets a value in session storage after stringifying it.
 * @param {string} key - The key for the session storage entry.
 * @param {T} value - The value to store; will be JSON stringified.
 */
export function setSessionItem<T>(key: string, value: T): void {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(`Error setting sessionStorage item with key "${key}":`, error);
    }
}

/**
 * Retrieves a value from session storage and parses it.
 * @param {string} key - The key of the session storage entry.
 * @returns {T | null} - The parsed value, or null if not found or if an error occurs.
 */
export function getSessionItem<T>(key: string): T | null {
    try {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) as T : null;
    } catch (error) {
        console.error(`Error getting sessionStorage item with key "${key}":`, error);
        return null;
    }
}

/**
 * Removes an item from session storage.
 * @param {string} key - The key of the session storage entry to remove.
 */
export function removeSessionItem(key: string): void {
    try {
        sessionStorage.removeItem(key);
    } catch (error) {
        console.error(`Error removing sessionStorage item with key "${key}":`, error);
    }
}

/**
 * Clears all items from session storage.
 */
export function clearSession(): void {
    try {
        sessionStorage.clear();
    } catch (error) {
        console.error("Error clearing sessionStorage:", error);
    }
}

/**
 * Checks if a session storage item exists.
 * @param {string} key - The key of the session storage entry to check.
 * @returns {boolean} - True if the item exists, false otherwise.
 */
export function hasSessionItem(key: string): boolean {
    return getSessionItem(key) !== null;
}

/**
 * Retrieves all keys stored in session storage.
 * @returns {string[]} - An array of all session storage keys.
 */
export function getSessionKeys(): string[] {
    return Object.keys(sessionStorage);
}
