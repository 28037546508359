import React, { useEffect, useMemo, useState } from 'react';

// Inline styles for simplicity
const styles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        color: '#333',
    },
    dropdown: {
        padding: '0.625rem',
        fontSize: '1rem',
        borderRadius: '0.625rem'
    },
};


export const WalletBalance = ({
    accounts
}: { accounts: any }) => {
    const wallets = useMemo(() => {
        if (accounts) {
            return accounts.map((account: any) => {
                return {
                    id: account.id,
                    name: account.type.name,
                    balance: account.balance,
                    symbol: account.currency.symbolNative
                }
            })
        }
        return null
    }, [accounts])

    // State to hold the selected wallet
    const [selectedWallet, setSelectedWallet] = useState<any>(null);

    // Handler for selecting a different wallet
    const handleWalletChange = (event: any) => {
        const walletId = parseInt(event.target.value, 10);
        const wallet = wallets.find((w: any) => w.id === walletId);
        setSelectedWallet(wallet);
    };

    const capitalizeFirstLetter = (val: any) => {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    useEffect(() => {
        if (wallets && wallets.length > 0) {
            setSelectedWallet(wallets[0])
        }
    }, [wallets])

    return (
        <div style={styles.container}>
            {/* Dropdown to select different wallets */}
            {selectedWallet ? (
                <select
                    title='Wallet'
                    value={selectedWallet.id}
                    onChange={handleWalletChange}
                    style={styles.dropdown}
                >
                    {wallets.map((wallet: any) => (
                        <option key={wallet.id} value={wallet.id}>
                            {capitalizeFirstLetter(wallet.name ?? "")} {wallet.symbol} {wallet.balance}
                        </option>
                    ))}
                </select>
            ) : null}
        </div>
    );
};
