import axios from "axios";
import getConfig from "../config/getConfig";

const baseURL = `${getConfig().API_URL_GAME_PROVIDERS}/gp/evoplay/game`;

const apiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
