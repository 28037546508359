import { Nav } from "../nav/Nav";
import { Footer } from "../footer/Footer";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return (
    <>
      <Nav />
      <Outlet />
      <Footer />
    </>
  );
};

export default AppLayout;
