import { useState } from "react";
import pro1Img from "../../img/games/pro1.png";
import pro2Img from "../../img/games/pro2.png";
import pro3Img from "../../img/games/pro3.png";
import pro4Img from "../../img/games/pro4.png";
import { TopMenuTab } from "../../types/TopMenuTab";
import TopMenu from "../../components/TopMenu";

export const Promotion = () => {
  const [selectedTab, setSelectedTab] = useState<TopMenuTab>(TopMenuTab.All);
  return (
    <div>
      <TopMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <section className="main__body__area">
        <div className="container">
          <div className="row g-0">
            <div className="promotions__wrap mt__30 pb-60">
              <h3>Promotions</h3>
              <div className="row g-4">
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="promo__items">
                    <div className="promo__content">
                      <h3>Cashback Bonus</h3>
                      <h2>20% up to €800</h2>
                      <a href="#0" className="cmn--btn2">
                        <span>Get Bonus</span>
                      </a>
                    </div>
                    <div className="promo__thumb">
                      <img src={pro2Img} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="promo__items">
                    <div className="promo__content">
                      <h3>Casino Welcome bonus</h3>
                      <h2>50% up to €900</h2>
                      <a href="#0" className="cmn--btn2">
                        <span>Get Bonus</span>
                      </a>
                    </div>
                    <div className="promo__thumb">
                      <img src={pro1Img} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="promo__items">
                    <div className="promo__content">
                      <h3>Weekend Reload Bonus</h3>
                      <h2>€700 + 50 Bonus Spins</h2>
                      <a href="#0" className="cmn--btn2">
                        <span>Get Bonus</span>
                      </a>
                    </div>
                    <div className="promo__thumb">
                      <img src={pro3Img} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="promo__items">
                    <div className="promo__content">
                      <h3>Casino Welcome bonus</h3>
                      <h2>25% up to €4,000</h2>
                      <a href="#0" className="cmn--btn2">
                        <span>Get Bonus</span>
                      </a>
                    </div>
                    <div className="promo__thumb">
                      <img src={pro4Img} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="footer__menu d-lg-none">
          <li>
            <a
              href="sportsbetting.html"
              className="d-grid justify-content-center"
            >
              <span>
                <i className="fas fa-table-tennis" />
              </span>
              <span className="texta">Sports</span>
            </a>
          </li>
          <li>
            <a
              href="#0"
              className="d-grid justify-content-center"
              data-bs-toggle="modal"
              data-bs-target="#eventsp"
            >
              <span>
                <i className="fa-solid fa-gift" />
              </span>
              <span className="texta">Events</span>
            </a>
          </li>
          <li className="header-bartwo d-lg-none">
            <span className="bars">
              <i className="fas fa-bars" />
            </span>
            <span className="cros">
              {" "}
              <i className="fa-solid fa-xmark" />
            </span>
          </li>
          <li>
            <a
              href="#0"
              className="d-grid justify-content-center"
              data-bs-toggle="modal"
              data-bs-target="#betsp"
            >
              <span>
                {" "}
                <i className="fas fa-ticket-alt" />
              </span>
              <span className="texta">My Bet</span>
            </a>
          </li>
          <li>
            <a href="dashboard.html" className="d-grid justify-content-center">
              <span>
                {" "}
                <i className="far fa-user-circle" />
              </span>
              <span className="texta"> Account</span>
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};
